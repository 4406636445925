<template>
  <div>
    <p>Total Number of Practices: {{ practices.length }}</p>
    <router-link :to="{name: 'CreatePractice' }">New Practice</router-link>
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="practice in practices" :key="practice.id">
          <td>{{ practice.name }}</td>
          <td>
            <router-link :to="{ name: 'PracticeDetails', params: { id: practice.id }}">View Details</router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const practices = computed(() => (store.state.practices as { name: string }[]).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));

    return {
      practices,
    };
  },
};
</script>

<style></style>
