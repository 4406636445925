
import { computed, onMounted, ref } from "vue";
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    const practices = computed(() => (store.state.practices as { name: string }[]).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));

    return {
      practices,
    };
  },
};
